import React, { useEffect, useRef, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import Glide, {
  Controls,
  Autoplay,
} from "@glidejs/glide/dist/glide.modular.esm"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import "./style.about.scss"
import Misc from "../../components/home/components/misc"

import AboutUs01 from "/static/about/1_关于我们_图1.png"
import AboutUs02 from "/static/about/1_关于我们_图2.png"
import AboutUs03 from "/static/about/1_关于我们_图3.png"
import AboutUs04 from "/static/about/1_关于我们_图4.png"
import IOS9001 from "/static/about/1_IOS 9001.png"
import IOS27001 from "/static/about/1_IOS 27001.png"
import GaryImg from "/static/about/2_1Gary.png"
import JeffreyImg from "/static/about/2_2Jeffery.png"
import CathyImg from "/static/about/2_3Cathy.png"
import LargeCircle from "/static/about/3_icon_大圈圈.png"
import SmallCircle from "/static/about/3_icon_小圈圈.png"
import RightArrow from "/static/about/3_icon_右.png"
import LeftArrow from "/static/about/3_icon_左.png"
import Award1 from "/static/about/4_图01.png"
import Award2 from "/static/about/4_图02.png"
import Award3 from "/static/about/4_图03.png"
import Award4 from "/static/about/4_图04.png"
import Award5 from "/static/about/4_图05.png"
import Award6 from "/static/about/4_图06.png"
import Award7 from "/static/about/4_图07.png"
import Award8 from "/static/about/4_图08.png"
import Award9 from "/static/about/4_图09.png"
import AddrIcon from "/static/about/5_公司地址icon.png"
import CoppIcon from "/static/about/5_商务合作icon.png"
import WechatIcon from "/static/about/6_企业微信.png"
import TiktokIcon from "/static/about/6_抖音.png"
import { Script } from "gatsby"

const pointSpan = 150

const About = () => {
  const sliderRef = useRef(null)
  const glideTrackRef = useRef(null)
  const [position, setPosition] = useState(0)
  const isBrowser = () => typeof window !== "undefined"

  useEffect(() => {
    if (isBrowser()) {
      window.BMAP_PROTOCOL = "https"
      window.BMapGL_loadScriptTime = new Date().getTime()
    }
  }, [])

  useEffect(() => {
    toggleEndPoints(0)
  }, [])

  const toggleEndPoints = position => {
    const current = position
    const next = position + 8
    for (let i = 0; i < glideTrackRef.current.children.length; i++) {
      glideTrackRef.current?.children[i].classList.remove("hiden")
      if (i === current || i === next) {
        glideTrackRef.current?.children[i].classList.add("hiden")
      }
    }
  }

  useEffect(() => {
    const slider = new Glide(sliderRef.current, {
      type: "carousel",
      autoplay: 3000,
      hoverpause: true,
    })
    slider.mount({ Controls, Autoplay })
    return () => {
      slider.destroy()
    }
  }, [])

  useEffect(() => {
    let nthPoint = 0
    for (let i = 0; i < glideTrackRef.current.children.length; i++) {
      if (
        glideTrackRef.current.children[i].className
          .split(" ")
          .includes(`position__${position}`)
      ) {
        nthPoint = i
        break
      }
    }
    glideTrackRef.current.style.transform = `translateX(-${
      pointSpan * nthPoint
    }px)`
    toggleEndPoints(nthPoint)
  }, [position])

  const handleLeft = () => {
    if (position > 0) {
      setPosition(position - 1)
    }
  }
  const handleRight = () => {
    if (position < 4) {
      setPosition(position + 1)
    }
  }

  const baiduScriptLoaded = () => {
    if (typeof window !== "undefined") {
      const map = new window.BMapGL.Map("map")
      const dorabotPoint = new window.BMapGL.Point(113.902, 22.484)
      map.centerAndZoom(dorabotPoint, 12)

      map.enableScrollWheelZoom(true)
      const opts = {
        width: 360,
        height: 20,
      }
      const infoWindow = new window.BMapGL.InfoWindow(
        "广东省深圳市南山区招商街道赤湾二路宝湾仓库H3-3库",
        opts
      )
      const marker = new window.BMapGL.Marker(dorabotPoint)
      map.addOverlay(marker)
      map.openInfoWindow(infoWindow, dorabotPoint)

      marker.addEventListener("click", function () {
        map.openInfoWindow(infoWindow, dorabotPoint)
      })
    }
  }

  return (
    <Layout>
      <Script
        type="text/javascript"
        src="https://api.map.baidu.com/getscript?type=webgl&v=1.0&ak=LC1QbijdPbcFFmTtdjar7LDUtK7PKTPl&services=&t=20230810131704"
        onLoad={baiduScriptLoaded}
      ></Script>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://api.map.baidu.com/res/webgl/10/bmap.css"
      />
      <Seo
        title="关于我们-蓝胖智汇Doraopt"
        keywords="蓝胖智汇,Doraopt"
        description="蓝胖智汇专注自研装箱软件、装柜软件与物流碳中和管理，为客户提供装箱装柜软件、集装箱装柜软件的仓储物流场景的多项智化升级服务，帮助客户提高供应链决策效率，降低企业管理成本。"
      />
      <div className="relative">
        <StaticImage
          src="./banner.png"
          alt="关于我们-蓝胖智汇"
          className="w-full"
        />
        <h1 className="absolute top-28 left-24 text-white">关于我们</h1>
      </div>
      <div className="about-cotainer">
        <div id="about-lead">
          <div className="title">关于我们</div>
          <div className="content">
            <div className="left">
              <div className="para">
                "蓝胖智汇”是深圳蓝胖子机器智能旗下的供应链智能决策AI软件品牌，以技术驱动打造绿色、智能的可持续供应链为使命。
              </div>
              <div className="para">
                我们拥有全球领先的自研智能决策计算平台DoraOpt，综合应用时间空间多目标优化、复杂系统认知模拟、深度强化学习、规则引擎等核心技术，为客户提供应用于仓储物流场景的多项数智化升级服务，提高供应链决策效率，降低企业管理成本。
              </div>
              <div className="para">
                平台服务已在零售、电商、汽车、化工等多个行业细分行业落地应用，服务对象包括宜家、马士基、巴斯夫、福耀玻璃等多家世界500强和行业龙头企业，相关案例多次荣获行业奖项。
              </div>
            </div>
            <div className="right">
              <div className="glide" ref={sliderRef}>
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">
                    <li className="glide__slide" style={{ width: 540 }}>
                      <img src={AboutUs01} alt="团队成员-1" />
                    </li>
                    <li className="glide__slide" style={{ width: 540 }}>
                      <img src={AboutUs02} alt="团队成员-2" />
                    </li>
                    <li className="glide__slide" style={{ width: 540 }}>
                      <img src={AboutUs03} alt="团队成员-3" />
                    </li>
                    <li className="glide__slide" style={{ width: 540 }}>
                      <img src={AboutUs04} alt="团队成员-4" />
                    </li>
                  </ul>
                </div>
                <div className="glide__bullets" data-glide-el="controls[nav]">
                  <button
                    className="glide__bullet"
                    data-glide-dir="=0"
                  ></button>
                  <button
                    className="glide__bullet"
                    data-glide-dir="=1"
                  ></button>
                  <button
                    className="glide__bullet"
                    data-glide-dir="=2"
                  ></button>
                  <button
                    className="glide__bullet"
                    data-glide-dir="=3"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="achievements">
          <div className="content">
            <div className="top">
              <div className="piece">
                <div className="ip">发明专利</div>
                <div className="qty">80项</div>
              </div>
              <div className="piece">
                <div className="ip">实用新型专利</div>
                <div className="qty">83项</div>
              </div>
              <div className="piece">
                <div className="ip">外观设计专利</div>
                <div className="qty">21项</div>
              </div>
              <div className="piece">
                <div className="ip">软件著作权</div>
                <div className="qty">37项</div>
              </div>
            </div>
            <div className="middle">
              <div className="left"></div>
              <div className="text">获得合规认证</div>
              <div className="right"></div>
            </div>
            <div className="bottom">
              <div className="patent">
                <img src={IOS9001} alt="ISO9001" />
                <div className="text">IOS 9001</div>
              </div>
              <div className="patent">
                <img src={IOS27001} alt="ISO27001" />
                <div className="text">IOS 27001</div>
              </div>
            </div>
          </div>
        </div>
        <div id="team">
          <div className="content">
            <div className="title">杰出团队</div>
            <div className="desc">
              核心技术成员来自卡内基梅隆，北京大学，澳州国立等全球顶级院校的AI算法、物理、数学及计算机系统等专业。博士占比7%，硕士占比80%。
            </div>
            <div className="cards">
              <div className="card">
                <div className="avatar">
                  <img src={GaryImg} alt="团队成员-5" />
                </div>
                <div className="body">
                  <div className="name">
                    Gary Ge <span>AIoT负责人</span>
                  </div>
                  <div className="titles">
                    <p>澳洲国立大学人工智能博士</p>
                    <p>澳洲国立大学计算机学院荣誉讲师</p>
                    <p>海外高层次人才</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="avatar">
                  <img src={JeffreyImg} alt="团队成员-6" />
                </div>
                <div className="body">
                  <div className="name">
                    Jeffery Yang <span>AIoT产品总监</span>
                  </div>
                  <div className="titles">
                    <p>新南威尔士大学应用物理博士</p>
                    <p>马克斯普朗学会访问学者</p>
                    <p>海外高层次人才</p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="avatar">
                  <img src={CathyImg} alt="团队成员-7" />
                </div>
                <div className="body">
                  <div className="name">
                    Cathy Chen <span>算法科学家</span>
                  </div>
                  <div className="titles">
                    <p>美国西北大学数学硕士(纯数)</p>
                    <p>北京大学元培班本科</p>
                    <p>全国奥林匹克大赛银奖</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="development">
          <div className="content">
            <div className="title">发展历程</div>
            <div className="glide__container">
              <div
                className="glide__track"
                id="glide_track"
                ref={glideTrackRef}
              >
                <div className="end-point position__0"></div>
                <div className="point">
                  <img className="large" src={LargeCircle} alt="icon" />
                  <div className="year">2020</div>
                </div>
                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="top">
                    <div className="text">与联邦快递建立合作</div>
                    <div className="text">并成功落地</div>
                    <div className="month">12月</div>
                  </div>
                </div>
                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="bottom">
                    <div className="month">10月</div>
                    <div className="text">与泸州老窖签署</div>
                    <div className="text">战略合作</div>
                  </div>
                </div>
                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="top">
                    <div className="text">成功落地</div>
                    <div className="text">DHL韩国</div>
                    <div className="month">8月</div>
                  </div>
                </div>
                <div className="point position__1">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="bottom">
                    <div className="month">7月</div>
                    <div className="text">获评“2021”碳中和</div>
                    <div className="text">科创先锋</div>
                  </div>
                </div>
                <div className="point">
                  <img className="large" src={LargeCircle} alt="icon" />
                  <div className="year">2019</div>
                </div>
                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="top">
                    <div className="text">入选“WEF”全球</div>
                    <div className="text">技术先锋</div>
                    <div className="month">9月</div>
                  </div>
                </div>

                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="bottom">
                    <div className="month">7月</div>
                    <div className="text">与BASF建立</div>
                    <div className="text">战略合作</div>
                  </div>
                </div>
                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="top">
                    <div className="text">首次落地智能制造</div>
                    <div className="text">行业应用</div>
                    <div className="month">4月</div>
                  </div>
                </div>
                <div className="point position__2">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="bottom">
                    <div className="month">3月</div>
                    <div className="text">运营广州办公室</div>
                  </div>
                </div>
                <div className="point">
                  <img className="large" src={LargeCircle} alt="icon" />
                  <div className="year">2018</div>
                </div>
                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="top">
                    <div className="text">运营美国办公室</div>
                    <div className="month">11月</div>
                  </div>
                </div>
                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="bottom">
                    <div className="month">8月</div>
                    <div className="text">运营澳洲办公室</div>
                  </div>
                </div>
                <div className="point position__3">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="top">
                    <div className="text">UPS第一任中国区总裁</div>
                    <div className="text">Perry Chao加盟蓝胖子</div>
                    <div className="month">7月</div>
                  </div>
                </div>
                <div className="point">
                  <img className="large" src={LargeCircle} alt="icon" />
                  <div className="year">2017</div>
                </div>
                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="top">
                    <div className="text">亚马逊机器人比赛全球冠军</div>
                    <div className="text">A.Tow加入蓝胖子</div>
                    <div className="month">11月</div>
                  </div>
                </div>
                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="bottom">
                    <div className="month">9月</div>
                    <div className="text">与中美物流巨头</div>
                    <div className="text">建立战略合作</div>
                  </div>
                </div>
                <div className="point position__4">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="top">
                    <div className="text">总部搬至深圳赤湾</div>
                    <div className="month">8月</div>
                  </div>
                </div>
                <div className="point">
                  <img className="large" src={LargeCircle} alt="icon" />
                  <div className="year">2016</div>
                </div>
                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="top">
                    <div className="text">研发出第一款通用型</div>
                    <div className="text">机器人DoraMOMA</div>
                    <div className="month">11月</div>
                  </div>
                </div>
                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="bottom">
                    <div className="text">IROS机器人抓取大赛</div>
                    <div className="text">第二名</div>
                    <div className="month">10月</div>
                  </div>
                </div>
                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="top">
                    <div className="text">成立美国办公室</div>
                    <div className="month">5月</div>
                  </div>
                </div>
                <div className="point">
                  <img className="large" src={LargeCircle} alt="icon" />
                  <div className="year">2015</div>
                </div>

                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="top">
                    <div className="text">亚马逊机器人大赛</div>
                    <div className="text">决赛轮</div>
                    <div className="month">5月</div>
                  </div>
                </div>
                <div className="point">
                  <img className="small" src={SmallCircle} alt="icon" />
                  <div className="bottom">
                    <div className="text">开启商业化运作</div>
                    <div className="month">5月</div>
                  </div>
                </div>
                <div className="end-point"></div>
                <div className="line"></div>
              </div>
              <div className="glide__arrows" data-glide-el="controls">
                <img
                  src={LeftArrow}
                  alt="icon"
                  className="glide__arrow glide__arrow--left arrow arrow-left"
                  data-glide-dir="<"
                  onClick={handleLeft}
                />
                <img
                  src={RightArrow}
                  alt="icon"
                  className="glide__arrow glide__arrow--right arrow arrow-right"
                  data-glide-dir=">"
                  onClick={handleRight}
                />
              </div>
            </div>
          </div>
        </div>
        <div id="awards">
          <div className="title">奖项荣誉</div>
          <div className="cards">
            <div className="card">
              <div className="card-content">
                <div className="avatar">
                  <img src={Award1} alt="宜家供应链亚太区创新先锋奖" />
                </div>
                <div className="body">
                  FY23英特宜家亚太区仓储物流 服务供应商创新先锋
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="avatar">
                  <img src={Award2} alt="阿里巴巴诸神之战新锐奖" />
                </div>
                <div className="body">
                  Create 2022阿里巴巴诸神之战 “智能制造赛道”全球总决赛 新锐奖
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="avatar">
                  <img
                    src={Award3}
                    alt="2021首届国际碳中和30人论坛碳中和科创先锋奖"
                  />
                </div>
                <div className="body">
                  2021首届国际碳中和30人论坛 碳中和科创先锋
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="avatar">
                  <img src={Award4} alt="广东工业智造创新大赛产业创新奖" />
                </div>
                <div className="body">
                  广东工业制造创新大寒(2021) 产业创新寒铜奖
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="avatar">
                  <img src={Award5} alt="安徽省CIO数字企业卓越服务商" />
                </div>
                <div className="body">2021年安徽数字企业卓越服务商</div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="avatar">
                  <img
                    src={Award6}
                    alt="2021全国电商与零售物流发展论坛物流创新奖"
                  />
                </div>
                <div className="body">全国电商与零售物流创新技术奖</div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="avatar">
                  <img src={Award7} alt="2021LOG低碳供应链物流创新优秀企业" />
                </div>
                <div className="body">2021 LOG低碳供应链 物流创新优秀企业</div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="avatar">
                  <img src={Award8} alt="运筹OR帷幄杰出贡献奖" />
                </div>
                <div className="body">2022年OR企业评选 杰出贡献奖</div>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="avatar">
                  <img src={Award9} alt="CB Insights AI全球100强" />
                </div>
                <div className="body">2020 CB Insights Al 100</div>
              </div>
            </div>
          </div>
          <button
            className="check-more"
            gtm-id="about-us-check-more-achievements"
          >
            查看更多
          </button>
        </div>
        <div id="contact-us">
          <div className="content">
            <div className="title">联系我们</div>
            <div className="card">
              <div className="left">
                <section>
                  <div className="line1">
                    <img src={AddrIcon} alt="公司地址" />
                    <div className="text">公司地址</div>
                  </div>
                  <div className="line2">
                    广东省深圳市南山区招商街道赤湾二路宝湾仓库H3-3＆-4库（嘿吼小镇）
                  </div>
                </section>
                <section>
                  <div className="line1">
                    <img src={CoppIcon} alt="商务合作" />
                    <div className="text">商务合作</div>
                  </div>
                  <div className="line2">郑小姐：13265810512</div>
                </section>
              </div>
              <div id="map"></div>
            </div>
          </div>
        </div>
        <div id="official-accounts">
          <div className="title">官方账号</div>
          <div className="row">
            <div className="card">
              <img src={WechatIcon} alt="企业微信" />
              <div className="text">蓝胖智汇官方企微</div>
            </div>
            <div className="card">
              <img src={TiktokIcon} alt="抖音" />
              <div className="text">装满满官方抖音</div>
            </div>
          </div>
        </div>
        <Misc />
      </div>
    </Layout>
  )
}

export default About
